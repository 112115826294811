a {
    text-decoration: none;
    color: #2471a3 ;
}

.height100vh {
 height: 100vh;
}

.height90vh {
 height: 90vh;
}

.dropzone.disabled {
    opacity: 0.6;
}

.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
}